<template>
  <div>
    <div class="page-content-block">
      <div class="container">
        <div class="row">

          <b-modal id="modal-1" size="xl">
            <iframe :src="modalData"
                    frameBorder="0"
                    style="width: 100%; height: 100%"/>
            <template v-slot:modal-footer>
              <div class="w-100">

              </div>
            </template>
          </b-modal>

          <div class="widget-carousel-block">
            <div class="widget-carousel">
              <VueSlickCarousel v-bind="settings" v-if='data.length' @init="progress"
                                @lazyLoad="progress" @afterChange="afterChange" ref="carousel">

                <ItemV2 :data="item" v-for="item in data" :key="item.id"/>

                <template #prevArrow>
                  <i class="fas fa-long-arrow-alt-left"></i>
                </template>

                <template #nextArrow>
                  <div class="custom-arrow">
                    <i class="fas fa-long-arrow-alt-right"></i>
                  </div>
                </template>

              </VueSlickCarousel>
              <span style="margin-left: 40px" @click="showPrev"><i
                  class="fas fa-long-arrow-alt-left nav-arrow"></i></span>
              <span style="margin-left: 5px; margin-right: 5px"></span>
              <span @click="showNext"><i class="fas fa-long-arrow-alt-right nav-arrow"></i></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

.slick-slide {
  margin: 5px;
}

.nav-arrow {
  font-size: 32px;
}

.nav-arrow:hover {
  cursor: pointer;
}

</style>

<script>

import ItemV2 from "./ItemV2";
import axios from 'axios'
import moment from 'moment'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
  metaInfo() {
    return {
      title: "News List View 2"
    };
  },
  props: {
    accountId: {
      type: String
    },
    pageSize: {
      type: Number
    },
    templateId: {
      type: Number
    }
  },
  data() {
    return {
      settings: {
        arrows: false,
        dots: false,
        centerMode: false,
        infinite: false,
        slidesToShow: 3,
        accessibility: false,
        lazyLoad: "ondemand",
        adaptiveHeight: true
      },
      currentSlide: 0,
      arrowOption: {},
      loaderCount: 4,
      baseUrl: '',
      finalPageSize: 10,
      pageNumber: 1,
      pageCount: 0,
      data: [],
      modalData: '',
      yearOptions: [],
      selectedYear: '',
      formTypeOptions: [],
      selectedFormType: ''
    }
  },
  computed: {},
  components: {
    VueSlickCarousel,
    ItemV2
  },
  watch: {
    'selectedYear': {
      handler() {
        this.data = [];
        this.pageNumber = 1;
        this.getData();
      },
      deep: true
    },
    'selectedFormType': {
      handler() {
        this.data = [];
        this.pageNumber = 1;
        this.getData();
      },
      deep: true
    }
  },
  methods: {
    async getData() {
      this.loaderCount = this.finalPageSize;
      let passAccountId = this.accountId;
      if (!passAccountId) {
        passAccountId = 'FtP9YENQuQ1zCDUVer8P';
      }
      let response = await axios.get(`sec/list?accountId=${passAccountId}&pageSize=${decodeURIComponent(this.finalPageSize)}&pageNumber=${this.pageNumber - 1}&year=${this.selectedYear}&formType=${this.selectedFormType}`);
      const res = response.data;
      if (res && res.status) {
        for (let i = 0; i < res.data.length; i++) {
          const item = res.data[i];
          const pdfData = this.getPDFLink(item.documentFormatFiles);
          this.data.push({
            id: item.id,
            date: moment(item.filedAt, 'YYYY-MM-DDTHH:mm:ssZ').format('MM/DD/YY'),
            form: item.formType,
            description: item.description,
            pdf: pdfData.mediaUrl,
            extraDocs: this.getExtraDocs(item.documentFormatFiles, pdfData.index),
            xbrl: '',
            filer: '',
            link: item.link,
            pageCount: pdfData.pageCount
          });
        }
        const pagination = res.pagination;
        const totalRecords = pagination.total;
        this.pageCount = parseInt((totalRecords + this.finalPageSize - 1) / this.finalPageSize);
      }
      this.loaderCount = 0;
    },
    getPDFLink(documentFormatFiles) {
      for (let i = 0; documentFormatFiles && i < documentFormatFiles.length; i++) {
        const file = documentFormatFiles[i];
        const extension = this.getExtension(file.documentUrl);
        const sequence = file.sequence;
        if (sequence === '1' && file.type !== 'GRAPHIC' && extension &&
            (extension.toLowerCase() === 'pdf' || extension.toLowerCase() === 'html' || extension.toLowerCase() === 'htm' ||
                extension.toLowerCase() === 'xml')) {
          return {mediaUrl: file.mediaUrl, index: i, pageCount: file.pageCount};
        }
      }
      return {mediaUrl: null, index: -1};
    },
    getExtraDocs(documentFormatFiles, index) {
      const otherDocs = [];
      for (let i = 0; documentFormatFiles && i < documentFormatFiles.length; i++) {
        const file = documentFormatFiles[i];
        const sequence = file.sequence;
        if (sequence !== '1' && i !== index && file.type && file.type !== 'GRAPHIC' && file.type.trim()) {
          otherDocs.push({
            mediaUrl: file.mediaUrl,
            type: file.type,
            id: file.sequence
          });
        }
      }
      return otherDocs;
    },
    getExtension(url) {
      const split = url.split('.');
      if (split && split.length > 0) {
        return split[split.length - 1];
      }
      return null;
    },
    async getFormTypes() {
      let passAccountId = this.accountId;
      if (!passAccountId) {
        passAccountId = 'FtP9YENQuQ1zCDUVer8P';
      }
      this.formTypeOptions.push({value: '', text: 'Filter by filing type'});
      let response = await axios.get(`sec/form-types?accountId=${passAccountId}`);
      const res = response.data;
      if (res && res.status) {
        for (let i = 0; i < res.value.length; i++) {
          const item = res.value[i];
          this.formTypeOptions.push(
              {value: item, text: item}
          );
        }
      }
    },
    progress(v) {
      if (v && v.length > 0) {
        const index = v[v.length - 1] + 1;
        if (index === this.data.length) {
          console.log('reached end');
          this.pageNumber = this.pageNumber + 1;
          this.getData();
        }
      }
    },
    showNext() {
      this.$refs.carousel.next()
    },
    showPrev() {
      if (this.currentSlide > 0)
        this.$refs.carousel.prev()
    },
    afterChange(slideIndex) {
      this.currentSlide = slideIndex;
    }
  },
  async beforeMount() {
    if (this.pageSize) {
      this.finalPageSize = this.pageSize;
    }
    let year = new Date().getFullYear();
    this.yearOptions.push({value: '', text: 'Filter by year'});
    for (let i = 0; i < 30; i++) {
      this.yearOptions.push(
          {value: year, text: year}
      );
      year--;
    }
  },
  async mounted() {
    this.getFormTypes();
    await this.getData();
    this.$root.$on('modalContent', (text) => {
      this.modalData = text;
      this.$root.$emit("bv::show::modal", "modal-1");
    });

    const openPDF = this.$route.query.openPDF;
    if (openPDF) {
      for (let i = 0; i < this.data.length; i++) {
        const item = this.data[i];
        if (item.id === openPDF) {
          window.open(item.pdf, '_blank').focus();
          //this.$root.$emit('modalContent', item.pdf);
          break;
        }
      }
    }
  }
}

</script>
