<template>
  <div id="app">
    <Template6 v-if="templateId && templateId===6" :account-id="accountId" :page-size="pageSize"
               :template-id="templateId"/>
    <Template5 v-if="templateId && templateId===5" :account-id="accountId" :page-size="pageSize"
               :template-id="templateId"/>
    <ListView4 v-if="templateId && templateId===4" :account-id="accountId" :page-size="pageSize"
               :template-id="templateId"/>
    <Template2 v-if="templateId && templateId===2" :account-id="accountId" :page-size="pageSize"
               :template-id="templateId"/>
    <Template1 v-if="(!templateId || templateId === 1)" :account-id="accountId" :page-size="pageSize"
               :template-id="templateId"/>
  </div>
</template>

<script>

import Template1 from "../components/template1/Template1";
import Template2 from "../components/template2/Template2";
import ListView4 from "../components/template4/ListView4";
import Template5 from "../components/template5";
import Template6 from "../components/template6";

export default {
  props: {
    accountId: {
      type: String
    },
    pageSize: {
      type: Number
    },
    templateId: {
      type: Number
    }
  },
  components: {
    Template1,
    Template2,
    ListView4,
    Template5,
    Template6
  }
}

</script>
