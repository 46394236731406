<template>
    <div class="widget-carousel-item">
        <h6>{{data.date}}</h6>
        <p class="header">{{data.description}}</p>
        <b-link @click="onDocClick(data.pdf, data.description)" :href="data.link" class="btn-readMore">Read more</b-link>
    </div>
</template>

<style>
    .header {
        line-height: 1.3em;
        height: 3.9em;
        overflow: hidden;
    }
</style>

<script>

    export default {
        props: {
            data: Object,
        },
        methods: {
            onclick() {
            },
            async onDocClick(mediaUrl, description) {
                if (mediaUrl) {
                    mediaUrl += `?v=${new Date().getTime()}`
                    window.open(mediaUrl, '_blank').focus();
                    //this.$root.$emit('modalContent', mediaUrl);
                    this.$gtm.trackEvent({
                      event: 'View SEC Filing',
                      category: 'view',
                      action: 'click',
                      label: 'View SEC Filing',
                      value: description
                    });
                }
            },
        }
    }

</script>
