<template>
  <div>
    <div class="row">
      <b-modal id="modal-1" size="xl">
        <iframe :src="modalData"
                frameBorder="0"
                style="width: 100%; height: 100%"/>
        <template v-slot:modal-footer>
          <div class="w-100">

          </div>
        </template>
      </b-modal>
      <Item :data="item" v-for="item in data" :key="item.id"/>
    </div>
    <div class="page-navigation">
      <paginate
          :page-count="pageCount"
          :page-range="1"
          :margin-pages="2"
          :click-handler="pageChange"
          :prev-text='leftArrow'
          :next-text='rightArrow'
          :container-class="'pagination'"
          :page-class="'page-item'"
          :page-link-class="'page-link'">
      </paginate>
    </div>
  </div>
</template>


<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3253dc !important;
  border-color: #3253dc !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>

<script>

import axios from 'axios'
import moment from 'moment'
import Item from './Item4'

export default {
  metaInfo() {
    return {
      title: "News List View"
    };
  },
  props: {
    accountId: {
      type: String
    },
    pageSize: {
      type: Number
    },
  },
  name: 'App',
  data() {
    return {
      modalData: '',
      loaderCount: 4,
      finalPageSize: 3,
      pageNumber: 1,
      pageCount: 0,
      data: [],
      baseUrl: ''
    }
  },
  computed: {
    leftArrow() {
      return '<svg\n' +
          '  width="24"\n' +
          '  height="24"\n' +
          '  viewBox="0 0 24 24"\n' +
          '  fill="none"\n' +
          '  xmlns="http://www.w3.org/2000/svg"\n' +
          '>\n' +
          '  <path\n' +
          '    d="M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z"\n' +
          '    fill="currentColor"\n' +
          '  />\n' +
          '</svg>';
    },
    rightArrow() {
      return '<svg\n' +
          '      width="24"\n' +
          '      height="24"\n' +
          '      viewBox="0 0 24 24"\n' +
          '      fill="none"\n' +
          '      xmlns="http://www.w3.org/2000/svg">\n' +
          '    <path\n' +
          '        d="M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z"\n' +
          '        fill="currentColor"\n' +
          '    />\n' +
          '  </svg>';
    }
  },
  components: {
    Item
  },
  methods: {
    pageChange(next) {
      this.data = [];
      this.pageNumber = next;
      this.getData();
    },
    async getData() {
      this.loaderCount = this.finalPageSize;
      let passAccountId = this.accountId;
      if (!passAccountId) {
        passAccountId = 'FtP9YENQuQ1zCDUVer8P';
      }
      let response = await axios.get(`sec/list?accountId=${passAccountId}&pageSize=${decodeURIComponent(this.finalPageSize)}&pageNumber=${this.pageNumber - 1}`);
      const res = response.data;
      if (res && res.status) {
        for (let i = 0; i < res.data.length; i++) {
          const item = res.data[i];
          const pdfData = this.getPDFLink(item.documentFormatFiles);
          this.data.push({
            id: item.id,
            date: moment(item.filedAt, 'YYYY-MM-DDTHH:mm:ssZ').format('MM/DD/YY'),
            form: item.formType,
            description: item.description,
            pdf: pdfData.mediaUrl,
            extraDocs: this.getExtraDocs(item.documentFormatFiles, pdfData.index),
            xbrl: '',
            filer: '',
            link: item.link,
            pageCount: pdfData.pageCount
          });
        }
        const pagination = res.pagination;
        const totalRecords = pagination.total;
        this.pageCount = parseInt((totalRecords + this.finalPageSize - 1) / this.finalPageSize);
      }
      this.loaderCount = 0;
    },
    getPDFLink(documentFormatFiles) {
      for (let i = 0; documentFormatFiles && i < documentFormatFiles.length; i++) {
        const file = documentFormatFiles[i];
        const extension = this.getExtension(file.documentUrl);
        const sequence = file.sequence;
        if (sequence === '1' && file.type !== 'GRAPHIC' && extension &&
            (extension.toLowerCase() === 'pdf' || extension.toLowerCase() === 'html' || extension.toLowerCase() === 'htm' ||
                extension.toLowerCase() === 'xml')) {
          return {mediaUrl: file.mediaUrl, index: i, pageCount: file.pageCount};
        }
      }
      for (let i = 0; i < documentFormatFiles.length; i++) {
        const file = documentFormatFiles[i];
        const extension = this.getExtension(file.documentUrl);
        const description = file.description;
        if (description === 'Complete submission text file' && extension.toLowerCase() === 'txt') {
          return {
            mediaUrl: file.mediaUrl,
            index: i,
            pageCount: file.pageCount,
          };
        }
      }
      return {mediaUrl: null, index: -1};
    },
    getExtraDocs(documentFormatFiles, index) {
      const otherDocs = [];
      for (let i = 0; documentFormatFiles && i < documentFormatFiles.length; i++) {
        const file = documentFormatFiles[i];
        const sequence = file.sequence;
        if (sequence !== '1' && i !== index && file.type && file.type !== 'GRAPHIC' && file.type.trim()) {
          otherDocs.push({
            mediaUrl: file.mediaUrl,
            type: file.type,
            id: file.sequence
          });
        }
      }
      return otherDocs;
    },
    getExtension(url) {
      const split = url.split('.');
      if (split && split.length > 0) {
        return split[split.length - 1];
      }
      return null;
    },
    progress(v) {
      if (v && v.length > 0) {
        const index = v[v.length - 1] + 1;
        if (index === this.data.length) {
          console.log('reached end');
          this.pageNumber = this.pageNumber + 1;
          this.getData();
        }
      }
    },
    showNext() {
      this.$refs.carousel.next()
    },
    showPrev() {
      if (this.currentSlide > 0)
        this.$refs.carousel.prev()
    },
    afterChange(slideIndex) {
      this.currentSlide = slideIndex;
    }
  },
  async beforeMount() {
    if (this.pageSize) {
      this.finalPageSize = this.pageSize;
    }
    /*let year = new Date().getFullYear();
    this.yearOptions.push({value: '', text: 'Filter by year'});
    for (let i = 0; i < 30; i++) {
        this.yearOptions.push(
            {value: year, text: year}
        );
        year--;
    }*/
  },
  async mounted() {
    //this.getFormTypes();
    await this.getData();
    this.$root.$on('modalContent', (text) => {
      this.modalData = text;
      this.$root.$emit("bv::show::modal", "modal-1");
    });

    const openPDF = this.$route.query.openPDF;
    if (openPDF) {
      for (let i = 0; i < this.data.length; i++) {
        const item = this.data[i];
        if (item.id === openPDF) {
          window.open(item.pdf, '_blank').focus();
          //this.$root.$emit('modalContent', item.pdf);
          break;
        }
      }
    }

  }
}

</script>
