<template>
  <div id="app">
    <Navigation :account-id="accountId" :page-size="pageSize" :template-id="templateId"/>
  </div>
</template>

<style lang="scss">
@import "assets/css/style.css";
@import "assets/css/style2.css";
@import "assets/css/animate.min.css";
@import "assets/css/font-awesome.min.css";
@import "assets/css/responsive.css";
</style>


<script>

import Navigation from './components/nav/Navigation'

export default {
  props: {
    prop1: {},
    prop2: {},
    prop3: {},
    accountId: {
      type: String
    },
    pageSize: {
      type: Number
    },
    templateId: {
      type: Number
    },
    gtmId: {
      type: String
    }
  },
  components: {
    Navigation
  },
  methods: {
    loadGTAScript () {
      //no script addition
      const iframe = document.createElement('iframe')
      iframe.style.display = 'none'
      iframe.style.visibility = 'hidden'
      iframe.height = 0
      iframe.width = 0
      iframe.src = `//www.googletagmanager.com/ns.html?id=${this.gtmId || process.env.VUE_APP_GTA_ID}`
      const noscript = document.createElement('noscript')
      noscript.appendChild(iframe)
      document.body.appendChild(noscript)

      //script addition
      const script = document.createElement('script')
      script.addEventListener('load', this.scriptLoadListener)
      window[process.env.VUE_APP_GTA_VARIABLE_NAME] = window[process.env.VUE_APP_GTA_VARIABLE_NAME] || []
      window[process.env.VUE_APP_GTA_VARIABLE_NAME].push({
        event: 'gtm.js',
        'gtm.start': new Date().getTime(),
      })
      const f = document.getElementsByTagName('script')[0]
      script.async = true
      script.src = `https://www.googletagmanager.com/gtag/js?id=${this.gtmId || process.env.VUE_APP_GTA_ID}&l=${process.env.VUE_APP_GTA_VARIABLE_NAME}`
      f.parentNode.insertBefore(script, f)
    },
    scriptLoadListener () {
      console.log('GTA Loaded')
    }
  },
  mounted () {

  },
  beforeMount () {
    //this.loadGTAScript()
  }
}

</script>
