<template>
    <tr>
        <td colspan="5" style="background: white">
            <content-loader width="600" height="30"
                            preserveAspectRatio="none"></content-loader>
        </td>
    </tr>
</template>

<script>
    import {ContentLoader} from 'vue-content-loader'

    export default {
        components: {
            ContentLoader
        },
    }
</script>
