<template>
    <div class="col-12 col-lg-4 wow fadeInUp" data-wow-delay=".5s"
         style="visibility: visible; animation-delay: 0.5s; animation-name: fadeInUp;">
        <div class="news-block">
            <h6>{{data.date}}</h6>
            <p>{{data.description}}</p>
            <!--<a :href="data.link" class="learn-more text-capitalize mt-4">View Article <i
                    class="fas fa-chevron-right"></i></a>-->
            <b-link @click="onDocClick(data.pdf, data.description)" :href="data.link" class="btn-readMore">Read more</b-link>
        </div>
    </div>
</template>

<script>

    export default {
        props: {
            data: Object,
        },
        methods: {
            onclick() {

            },
            async onDocClick(mediaUrl, description) {
              mediaUrl += `?v=${new Date().getTime()}`
              console.log(mediaUrl)
              if (mediaUrl) {
                  window.open(mediaUrl, '_blank').focus();
                  //this.$root.$emit('modalContent', mediaUrl);
                  this.$gtm.trackEvent({
                    event: 'View SEC Filing',
                    category: 'view',
                    action: 'click',
                    label: 'View SEC Filing',
                    value: description
                  });
                }
            },
        }
    }

</script>
