<template>
  <div id="app">
    <!--<div class="header-space"></div>-->
    <div class="page-content-block">
      <div class="container">
        <div class="row">
          <div class="form-group col-12 col-sm-6 col-lg-3">
            <b-form-select class="form-control custom-select" v-model="selectedFormType"
                           :options="formTypeOptions"></b-form-select>
          </div>
          <div class="form-group col-12 col-sm-6 col-lg-3">
            <b-form-select class="form-control custom-select" v-model="selectedYear"
                           :options="yearOptions"></b-form-select>
          </div>
        </div>
        <div class="row">
          <b-modal id="modal-1" size="xl">
            <iframe :src="modalData"
                    frameBorder="0"
                    style="width: 100%; height: 100%"/>
            <template v-slot:modal-footer>
              <div class="w-100">
              </div>
            </template>
          </b-modal>
          <div class="col-12 pt-5">
            <div class="table-responsive">
              <table class="table basic-table">
                <tbody>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Form</th>
                  <th scope="col">Description</th>
                  <th scope="col">PDF</th>
                  <!--<th scope="col">XBRL</th>
                  <th scope="col">Filer</th>-->
                  <th scope="col">Pages</th>
                </tr>

                <ContentLoader v-for="index in loaderCount" :key="index"/>
                <Item :data="item" v-for="item in data" :key="item.id"/>

                </tbody>
              </table>
            </div>
          </div>
        </div>


        <div class="page-navigation">
          <paginate
              :value="this.pageNumber"
              :page-count="pageCount"
              :page-range="1"
              :margin-pages="2"
              :click-handler="pageChange"
              :prev-text='leftArrow'
              :next-text='rightArrow'
              :container-class="'pagination'"
              :page-class="'page-item'"
              :page-link-class="'page-link'">
          </paginate>
        </div>

      </div>
    </div>
  </div>
</template>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3253dc !important;
  border-color: #3253dc !important;
}
</style>

<script>

import axios from 'axios';
import moment from 'moment';
import Item from './Item'
import ContentLoader from "../content-loader/ContentLoader";

export default {
  props: {
    accountId: {
      type: String
    },
    pageSize: {
      type: Number
    },
    templateId: {
      type: Number
    }
  },
  name: 'App',
  data() {
    return {
      loaderCount: 4,
      finalPageSize: 10,
      pageNumber: 1,
      pageCount: 0,
      data: [],
      modalData: '',
      yearOptions: [],
      selectedYear: '',
      formTypeOptions: [],
      selectedFormType: ''
    }
  },
  computed: {
    leftArrow() {
      return '<svg\n' +
          '  width="24"\n' +
          '  height="24"\n' +
          '  viewBox="0 0 24 24"\n' +
          '  fill="none"\n' +
          '  xmlns="http://www.w3.org/2000/svg"\n' +
          '>\n' +
          '  <path\n' +
          '    d="M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z"\n' +
          '    fill="currentColor"\n' +
          '  />\n' +
          '</svg>';
    },
    rightArrow() {
      return '<svg\n' +
          '      width="24"\n' +
          '      height="24"\n' +
          '      viewBox="0 0 24 24"\n' +
          '      fill="none"\n' +
          '      xmlns="http://www.w3.org/2000/svg">\n' +
          '    <path\n' +
          '        d="M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z"\n' +
          '        fill="currentColor"\n' +
          '    />\n' +
          '  </svg>';
    }
  },
  components: {
    Item,
    ContentLoader
  },
  watch: {
    'selectedYear': {
      handler() {
        this.data = [];
        this.pageNumber = 1;
        this.getData();
      },
      deep: true
    },
    'selectedFormType': {
      handler() {
        this.data = [];
        this.pageNumber = 1;
        this.getData();
      },
      deep: true
    }
  },
  methods: {
    pageChange(next) {
      this.data = [];
      this.pageNumber = next;
      this.getData();
    },
    async getData() {
      this.loaderCount = this.finalPageSize;
      let passAccountId = this.accountId;
      if (!passAccountId) {
        passAccountId = 'FtP9YENQuQ1zCDUVer8P';
      }
      let response = await axios.get(`sec/list?accountId=${passAccountId}&pageSize=${decodeURIComponent(this.finalPageSize)}&pageNumber=${this.pageNumber - 1}&year=${this.selectedYear}&formType=${this.selectedFormType}`);
      const res = response.data;
      if (res && res.status) {
        for (let i = 0; i < res.data.length; i++) {
          const item = res.data[i];
          const pdfData = this.getPDFLink(item.documentFormatFiles);
          this.data.push({
            id: item.id,
            date: moment(item.filedAt, 'YYYY-MM-DDTHH:mm:ssZ').format('MM/DD/YY'),
            form: item.formType,
            description: item.description,
            pdf: pdfData.mediaUrl,
            extraDocs: this.getExtraDocs(item.documentFormatFiles, pdfData.index),
            xbrl: '',
            filer: '',
            link: item.link,
            pageCount: pdfData.pageCount
          });
        }
        const pagination = res.pagination;
        const totalRecords = pagination.total;
        this.pageCount = parseInt((totalRecords + this.finalPageSize - 1) / this.finalPageSize);
      }
      this.loaderCount = 0;
    },
    getPDFLink(documentFormatFiles) {
      for (let i = 0; documentFormatFiles && i < documentFormatFiles.length; i++) {
        const file = documentFormatFiles[i];
        const extension = this.getExtension(file.documentUrl);
        const sequence = file.sequence;
        if (sequence === '1' && file.type !== 'GRAPHIC' && extension &&
            (extension.toLowerCase() === 'pdf' || extension.toLowerCase() === 'html' || extension.toLowerCase() === 'htm' ||
                extension.toLowerCase() === 'xml')) {
          return {mediaUrl: file.mediaUrl, index: i, pageCount: file.pageCount};
        }
      }
      return {mediaUrl: null, index: -1};
    },
    getExtraDocs(documentFormatFiles, index) {
      const otherDocs = [];
      for (let i = 0; documentFormatFiles && i < documentFormatFiles.length; i++) {
        const file = documentFormatFiles[i];
        const sequence = file.sequence;
        if (sequence !== '1' && i !== index && file.type && file.type !== 'GRAPHIC' && file.type.trim()) {
          otherDocs.push({
            mediaUrl: file.mediaUrl,
            type: file.type,
            id: file.sequence
          });
        }
      }
      return otherDocs;
    },
    getExtension(url) {
      const split = url.split('.');
      if (split && split.length > 0) {
        return split[split.length - 1];
      }
      return null;
    },
    async getFormTypes() {
      let passAccountId = this.accountId;
      if (!passAccountId) {
        passAccountId = 'FtP9YENQuQ1zCDUVer8P';
      }
      this.formTypeOptions.push({value: '', text: 'Filter by filing type'});
      let response = await axios.get(`sec/form-types?accountId=${passAccountId}`);
      const res = response.data;
      if (res && res.status) {
        for (let i = 0; i < res.value.length; i++) {
          const item = res.value[i];
          this.formTypeOptions.push(
              {value: item, text: item}
          );
        }
      }
    },
    async getYears() {
      let passAccountId = this.accountId;
      if (!passAccountId) {
        passAccountId = 'FtP9YENQuQ1zCDUVer8P';
      }
      this.yearOptions.push({value: '', text: 'Filter by year'});
      let response = await axios.get(`sec/filing-years?accountId=${passAccountId}`);
      const res = response.data;
      if (res && res.status) {
        for (let i = 0; i < res.value.length; i++) {
          const item = res.value[i];
          this.yearOptions.push(
              {value: item, text: item}
          );
        }
      }
    }
  },
  async mounted() {
    this.getFormTypes();
    this.getYears();
    await this.getData();
    this.$root.$on('modalContent', (text) => {
      this.modalData = text;
      this.$root.$emit("bv::show::modal", "modal-1");

      //move modal inside
      const $myWebComponent = document.querySelector("sec-widget");
      const modal = document.getElementById('#modal-1');
      console.log($myWebComponent);
      console.log(modal);
    });

    const openPDF = this.$route.query.openPDF;
    if (openPDF) {
      for (let i = 0; i < this.data.length; i++) {
        const item = this.data[i];
        if (item.id === openPDF) {
          window.open(item.pdf, '_blank').focus();
          //this.$root.$emit('modalContent', item.pdf);
          break;
        }
      }
    }

  },
  beforeMount() {
    if (this.pageSize) {
      this.finalPageSize = this.pageSize;
    }
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.modal-dialog {
  max-width: 100% !important;
  margin: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  height: 100% !important;
  display: flex !important;
  background: #ffffff !important;
  opacity: 0.9;
}


.modal-content {
  background: #ffffff !important;
}

.modal-header {
  border: 0px solid #dee2e6 !important;
}

.modal-footer {
  border: 0px solid #dee2e6 !important;
}

</style>
