<template>
    <div id="app">
        <Header/>
        <router-view :account-id="accountId" :page-size="pageSize" :template-id="templateId"/>
        <Footer/>
    </div>
</template>


<script>

    import Header from './Header'
    import Footer from './Footer'

    export default {
        props: {
            accountId: {
                type: String
            },
            pageSize: {
                type: Number
            },
            templateId: {
                type: Number
            }
        },
        components: {
            Header,
            Footer
        }
    }

</script>
